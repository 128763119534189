import { Vue } from 'vue-property-decorator';
import VueI18n from 'vue-i18n';

export class Rules {
  i18n: VueI18n;
  constructor(i18n: VueI18n) {
    this.i18n = i18n;
  }

  role = [(v: any) => !!v || this.i18n.t('validation.noRole')];
  email = [
    (v: string) =>
      /(^\w+[\w.!#$%&'*+/=?^_`{|}~-]*@\w+([.-]?\w)*(\.\w{2,4})+$)|(^.{0}$)/.test(v || '') ||
      this.i18n.t('validation.notEmail')
  ];
  password = [
    (v: string) =>
      (!!v &&
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!?,.:;$°<{}"'>|§&=\-/`´€()+~@_#%^*])(?=.{12,})/.test(v) &&
        /^\S+$/.test(v)) ||
      this.i18n.t('validation.securePassword')
  ];
  notEmpty = [(v: any) => !!v || this.i18n.t('validation.notEmpty')];
  phoneNumber = [
    (v: string) => /(^\+?[1-9]\d{6,14}$)|(^.{0}$)/.test(v || '') || this.i18n.t('validation.notPhoneNumber')
  ];
  orderItemPricePattern = [
    (v: any) => !v || /^([1-9]\d*|0?)(\.\d{1,5})?$/.test(v) || this.i18n.t('validation.orderItemPricePattern')
  ];
  positiveNumber = [(v: any) => !v || /^(\d+)$/.test(v) || this.i18n.t('validation.positiveNumber')];
  terminalId = [(v: string) => !v || /^\d{8}$/.test(v) || this.i18n.t('validation.terminalId')];
  personNumberLength = [(v: string) => !v || /^\d{10}$/.test(v) || this.i18n.t('validation.personNumberLength')];
  personNumberChecksum = [
    (v: string) => !v || this.validChecksumOfPersonNumber(v) || this.i18n.t('validation.personNumberChecksum')
  ];

  validChecksumOfPersonNumber(customerId: string) {
    const personNumberChecksumDivisor = 11;
    let dividend = 0;
    const weights = [7, 6, 5, 4, 3, 2];
    let digit = 0;
    for (const weight of weights) {
      dividend += weight * parseInt(customerId.substring(digit, ++digit), 10);
    }
    const remainder = dividend % personNumberChecksumDivisor;
    const checkDigitExpected = remainder < 2 ? '0' : (personNumberChecksumDivisor - remainder).toString();
    const checkDigitActual = customerId.substring(6, 7);

    return checkDigitExpected === checkDigitActual;
  }
}

export abstract class FormVue extends Vue {
  valid = false;

  rules = new Rules(this.$i18n);
}

export class Validator extends FormVue {}

export class FolderRules extends Rules {
  folder = [(v: string) => !v || /^[\w äöü&]{2,36}$/.test(v.trim()) || this.i18n.t('edoc.validation.folder')];
}
