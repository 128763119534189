<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" icon>
        <v-icon :size="size" :color="color">{{ icon }}</v-icon>
      </v-btn>
    </template>
    <div v-html="label" />
  </v-tooltip>
</template>

<script>
export default {
  name: 'IconTooltip',
  props: {
    icon: {
      type: String,
      required: false,
      default: 'mdi-information-outline'
    },
    label: {
      type: String,
      required: true
    },
    size: {
      type: String,
      required: false,
      default: ''
    },
    color: {
      type: String,
      required: false,
      default: ''
    }
  }
};
</script>
