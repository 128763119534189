import { IUser } from './IUser';
import { ERole } from './ERole';

export class User implements IUser {
  uid!: string;
  email!: string;
  emailVerified!: boolean;
  password!: string;
  displayName!: string;
  role!: ERole;
  disabled!: boolean;
  creationTime!: string;
  lastSignInTime!: string;

  public static Factory(data: IUser): User {
    const user = new User();
    Object.assign(user, data);
    return user;
  }
}
