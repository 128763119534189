<template>
  <v-card-title>
    <v-col cols="12">
      <v-row dense>
        <v-btn v-if="showBackButton" @click="$router.back()" class="mr-2" icon data-cy="back-button">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <span class="centered">
          <template v-if="loading">
            <v-progress-circular color="primary" indeterminate />
          </template>
          <template v-else>
            {{ title }}
          </template>
        </span>
        <v-spacer></v-spacer>
        <v-btn
          v-if="showSaveButton"
          :disabled="disableSaveButton"
          @click="$emit('save')"
          class="mx-2"
          color="primary"
          data-cy="save-button"
        >
          {{ $t('common.actions.save') }}
        </v-btn>
        <v-btn v-if="showDeleteButton" @click="$emit('remove')" color="primary" outlined
          >{{ $t('common.actions.delete') }}
        </v-btn>
        <v-btn v-if="showDeactivateButton" @click="$emit('deactivate')" color="primary" outlined>
          {{ $t('common.actions.deactivate') }}
        </v-btn>
        <v-btn v-if="showRefreshButton" @click="$emit('refresh')" class="mx-2" outlined color="primary">
          <v-icon small>mdi-refresh</v-icon>
        </v-btn>
        <v-btn v-if="showUploadButton" class="mr-2 white--text" @click="$emit('upload')" color="primary" outlined>
          {{ $t('upload.button') }}
          <v-icon right dark>mdi-cloud-upload</v-icon>
        </v-btn>
        <v-btn v-if="showCancelButton" @click="$emit('cancel')" color="primary" outlined
          >{{ $t('common.cancel') }}
        </v-btn>
      </v-row>
    </v-col>
  </v-card-title>
</template>

<script>
export default {
  name: 'CardTitleWithButtons',
  props: {
    title: {
      type: String,
      default: ''
    },
    showSaveButton: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    disableSaveButton: {
      type: Boolean,
      default: false
    },
    showCancelButton: {
      type: Boolean,
      default: false
    },
    showDeleteButton: {
      type: Boolean,
      default: true
    },
    showDeactivateButton: {
      type: Boolean,
      default: false
    },
    showBackButton: {
      type: Boolean,
      default: true
    },
    showUploadButton: {
      type: Boolean,
      default: false
    },
    disableUploadButton: {
      type: Boolean,
      default: true
    },
    showRefreshButton: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped>
.centered {
  align-self: center;
}
</style>
