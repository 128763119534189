





















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import SimpleUserTable from '@/components/tables/SimpleUserTable.vue';
@Component({
  components: { SimpleUserTable }
})
export default class UserPicker extends Vue {
  @Prop({
    type: String,
    default: ''
  })
  label!: string;

  @Prop({
    type: String,
    default: ''
  })
  textFieldClass!: string;

  @Prop({
    type: Object,
    default: () => null
  })
  value!: any;

  @Prop({
    type: Object,
    default: () => ({})
  })
  searchParams!: any;

  @Prop({
    type: Boolean,
    default: false
  })
  paginate!: boolean;

  selected: any = null;

  open = false;

  get selectedName() {
    return this.selected ? this.selected.displayName : '';
  }

  get nameLabel() {
    return this.label || this.$t('userSearch.select');
  }

  created() {
    this.selected = this.value || null;
  }

  @Watch('value')
  onValueChange(newVal: any) {
    this.selected = newVal;
  }

  onClick(item: any) {
    this.open = false;
    this.selected = item;
    this.$emit('input', this.selected);
  }
}
