
































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class TableFooter extends Vue {
  @Prop({ type: Boolean, default: false })
  disabled!: boolean;

  @Prop({ type: Array, default: () => [10, 20, 50, 100] })
  pageSizes!: number[];

  @Prop({ type: Number })
  page!: number;

  @Prop({ type: Number })
  perPage!: number;

  @Prop({ type: Number })
  totalPages!: number;

  @Prop({ type: String, default: '' })
  model!: string;

  get itemsModel() {
    return this.model ? this.model : this.$tc('tables.item', 2);
  }

  change() {
    this.$emit('change');
  }

  changePerPage(newVal: number) {
    this.$emit('update:per-page', newVal);
    this.$emit('update:page', 1);
    this.change();
  }
  changePage(newVal: number) {
    this.$emit('update:page', newVal);
    this.change();
  }
}
