








































































import IconTooltip from '@/components/IconTooltip.vue';
import CardTitleWithButtons from '@/components/CardTitleWithButtons.vue';
import { FormVue } from '@/components/forms/FormVue.ts';
import { Component, Prop } from 'vue-property-decorator';
import { FirebaseAuthService } from '@/services/firebase/FirebaseAuthService';
import { ERole } from '@/domain/enums/Role';
import UserPicker from '@/components/pickers/UserPicker.vue';
import { AuthService } from '@/services/AuthService';
import { User } from '@/model/User';

@Component({
  components: { IconTooltip, CardTitleWithButtons, UserPicker }
})
export default class UserDetails extends FormVue {
  @Prop({ type: String, required: false })
  userId!: string;

  currentUser: any = null;
  currentUserRole: ERole | null = null;
  items = [ERole.EDITOR, ERole.ADMIN];
  role: ERole[] = [];
  selectedUser: null | any = null;
  selectedRoles: any[] = [];
  currentSelectedRole: any = null;
  roleChanged = false;
  assignedTo: any = null;
  authService = new AuthService();
  show1 = false;
  show2 = true;

  get cardHeaderTitle() {
    return this.userId ? this.$t('administration.editUser') : this.$t('administration.createUser');
  }

  get showUserPicker() {
    return this.selectedRoles.some((role) => role.value === ERole.ADMIN);
  }

  get userPickerFilters() {
    return { role: ERole.ADMIN, includeAssigned: true };
  }

  async selectRole(item: { text: string; value: ERole }) {
    this.selectedRoles.push(item);
    this.currentSelectedRole = item;
    this.roleChanged = true;
    await this.$forceUpdate();
    this.currentSelectedRole = null;
  }

  async deleteRole(index: number) {
    this.currentSelectedRole = null;
    this.selectedRoles.splice(index, 1);
  }

  async created() {
    await this.getCurrentUser();
    if (this.userId) {
      this.selectedUser = await this.authService.getUser(this.userId);
    } else {
      this.selectedUser = new User();
    }
  }

  save() {
    if ((this.$refs.form as any).validate()) {
      if (this.userId) {
        try {
          this.authService.updateUser(this.selectedUser).then(() => {
            this.$showMessage(this.$t('administration.userUpdated'));
            this.$router.push({ name: 'Administration' });
          });
        } catch (err) {
          this.$showError(err);
        }
      } else {
        this.authService.createUser(this.selectedUser).then(() => {
          this.$showMessage(this.$t('administration.userCreated'));
          this.$router.push({ name: 'Administration' });
        });
      }
    }
  }

  cancel() {
    this.$router.push({ name: 'Administration' });
  }

  async getCurrentUser() {
    const firebaseUser = await FirebaseAuthService.getCurrentUser();
    if (!firebaseUser) {
      this.$showError(this.$t('common.errors.unknownError'));
      return;
    }
    this.currentUser = {
      uid: firebaseUser.uid
    };
    this.currentUserRole = (await FirebaseAuthService.getCurrentUserRoles()) || null;
  }
}
