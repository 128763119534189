







































import { TableVue } from '@/components/tables/TableVue.ts';
import { Component, Prop } from 'vue-property-decorator';
import { ListResponse } from '@/domain/models/ListResponse';
import { AuthService } from '@/services/AuthService';
import TableFooter from '@/components/tableTemplates/TableFooter.vue';

@Component({
  components: { TableFooter }
})
export default class SimpleUserTable extends TableVue<any> {
  @Prop({
    type: Object,
    default: () => ({})
  })
  searchParams!: any;

  @Prop({
    type: Boolean,
    default: false
  })
  paginate!: boolean;

  @Prop({
    type: Boolean,
    default: false
  })
  flat!: boolean;

  @Prop({
    type: String,
    default: ''
  })
  actionTitle!: string;

  searchQuery = '';
  authService = new AuthService();
  headers = [
    { text: this.$t('headers.name'), value: 'displayName' },
    { text: this.$t('headers.email'), value: 'email' },
    { text: this.actionTitle, value: 'actions', sortable: false, align: 'right' }
  ];

  created() {
    this.itemsPerPage = 20;
    this.loadData();
  }

  onClick(item: any) {
    this.$emit('click:row', item);
  }

  async fetchData(): Promise<ListResponse<any>> {
    if (this.searchQuery.length < 2 && this.searchQuery.length > 0) {
      return new ListResponse<any>(this.items, this.itemsPerPage, this.total, this.page);
    }
    try {
      return await this.authService.searchUsers(
        { search: this.searchQuery || undefined, ...this.searchParams },
        this.page,
        this.itemsPerPage
      );
    } catch (err) {
      this.$showError(this.$t('userSearch.errors.unknown').toString());
      throw err;
    }
  }
}
