import { Vue } from 'vue-property-decorator';
import { ListResponse } from '@/domain/models/ListResponse';
import dayjs from 'dayjs';
import { IPaginationRequest } from '@/domain/models/IPaginationRequest';

export abstract class TableVue<T> extends Vue {
  page = 1;
  total = 0;
  itemsPerPage = 10;
  searchQuery = '';
  items: T[] = [];
  loading = false;
  pages?: number;

  get pagination(): IPaginationRequest {
    return {
      page: this.page - 1,
      size: this.itemsPerPage
    };
  }

  get totalPages() {
    if (this.pages) {
      return this.pages;
    }
    if (this.pages === 0 || this.total === 0) {
      return 1;
    }
    return Math.ceil(this.total / this.itemsPerPage);
  }

  get disabledClasses() {
    return {
      'text--disabled': true,
      'row-disabled': true
    };
  }

  get activeClasses() {
    return {
      'row-active': true
    };
  }

  getActiveRowByDate(item: T) {
    const validUntil = (item as any).validUntil;
    if (!validUntil) {
      return this.activeClasses;
    }
    return dayjs().isAfter(dayjs(validUntil)) ? this.disabledClasses : this.activeClasses;
  }

  getActiveRow(item: T) {
    const active = (item as any).active;
    if (typeof active === 'boolean') {
      return active ? this.activeClasses : this.disabledClasses;
    }
    return this.activeClasses;
  }

  async loadData() {
    this.loading = true;
    try {
      const data = await this.fetchData();
      this.itemsPerPage = data.itemsPerPage;
      this.page = data.page;
      this.items = data.items;
      this.total = data.total;
      this.pages = data.totalPages;
    } catch (err) {
      console.warn('Data could not be loaded');
    } finally {
      this.loading = false;
    }
  }

  updateSearch(searchQuery: string) {
    this.page = 1;
    this.searchQuery = searchQuery;
    this.loadData().then();
  }

  abstract fetchData(): Promise<ListResponse<T>>;
}
